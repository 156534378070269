import React from 'react'

function Animation() {
    return (
        <div>



            <div className='chat-loader'>

            </div>


        </div>
    )
}

export default Animation
